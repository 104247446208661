import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SVG from "../images/page404.svg";

const NotFoundPage = () => (
  <div className="page-not-found">
    <SEO pageTitle="Opps! Page Not found" />

    <div className="wrapper">
      <h1>Hmm.</h1>
      <p>
        It seems that you're lost! The page you are looking for does not exist.
        Let us help guide you out and get you back home.
      </p>
      <div className="buttons">
        <Link className="home-btn" to="/">
          Home
        </Link>
        <br />
      </div>
    </div>
    <div className="space"></div>
  </div>
);

export default NotFoundPage;
